import React, { FC } from 'react';

type Props = {
  value: number | null | undefined;
  prefix?: string;
  suffix?: string;
};

const SpecLabel: FC<Props> = (props) => {
  return (
    <>
      {props.prefix && <small>{props.prefix}</small>}
      {props.value?.toLocaleString() ?? '-'}
      {props.suffix && <small>{props.suffix}</small>}
    </>
  );
};

export default SpecLabel;
