import React, { useEffect } from 'react';
import firebase from './utils/Firebase';

const CheckAppVersion = () => {
  useEffect(() => {
    const query = firebase.firestore().collection('app_versions').doc('admin');

    let savedLastReleasedAt: Date | null = null;
    const unregister = query.onSnapshot((snapshot) => {
      const data = snapshot.data();
      if (data?.last_released_at) {
        if (savedLastReleasedAt && data?.last_released_at.toDate() !== savedLastReleasedAt) {
          if (window.confirm('新しいバージョンがリリースされました。リロードします。')) {
            window.location.reload();
          }
        }
        savedLastReleasedAt = data?.last_released_at.toDate();
        console.log(savedLastReleasedAt);
      }
    });

    return () => {
      unregister();
    };
  }, []);

  return <></>;
};

export default CheckAppVersion;
