import { useEffect, useState } from 'react';
import Shop from '../../../models/shop/Shop';

const useShopsOnce = () => {
  const [shops, setShops] = useState<Shop[]>([]);
  const [loadingShops, setLoadingShops] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoadingShops(true);
        setShops(await Shop.getAll());
      } catch (e) {
        alert(e);
      } finally {
        setLoadingShops(false);
      }
    })();
  }, []);

  return { shops, loadingShops };
};

export default useShopsOnce;
