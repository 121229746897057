import React, { FC, useState } from 'react';

import { Button, ButtonGroup, Menu } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = {
  children: React.ReactNode;
};

const DropdownButtonMenu: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup variant="contained" className="btn-second m-2" color="primary">
        <Button className="btn-transition-none">操作</Button>
        <Button
          className="btn-transition-none px-2"
          color="primary"
          size="small"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <div className="p-3">{props.children}</div>
      </Menu>
    </>
  );
};

export default DropdownButtonMenu;
