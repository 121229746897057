import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Tooltip } from '@mui/material';

type Props = {
  to?: string;
  onClick?: () => void;
  tooltip: string;
  icon: React.ReactNode;
  color: 'primary' | 'first' | 'second' | 'success' | 'danger' | 'warning' | 'info' | 'dark';
  neutral: boolean;
};

const TableActionButton: React.FC<Props> = (props) => {
  const btnClass = `${props.neutral ? 'btn-neutral' : 'btn'}-${props.color}`;

  return (
    <Tooltip title={props.tooltip}>
      <NavLink to={props.to ?? '#'} onClick={props.onClick}>
        <Button
          className={`${btnClass} m-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center`}
        >
          {props.icon}
        </Button>
      </NavLink>
    </Tooltip>
  );
};

export default TableActionButton;
