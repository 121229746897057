import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const useStore = () => {
  const [isActiveSidebar, setIsActiveSidebar] = useLocalStorage('isActiveSidebar', false);
  const [isActiveSidebarMobile, setIsActiveSidebarMobile] = useLocalStorage('isActiveSidebarMobile', false);

  return {
    isActiveSidebar,
    setIsActiveSidebar,
    isActiveSidebarMobile,
    setIsActiveSidebarMobile,
  };
};

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const StoreContainer = createContainer(useStore);
