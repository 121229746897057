/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Content, PageTitle } from '../../layouts';
import CrewLevel from '../../models/crew-level/CrewLevel';
import { ContentLoader, FormActionButton } from '../../reusable';
import CrewLevelForm from './components/form/CrewLevelForm';

const CreateCrewLevel = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [executing, setExecuting] = useState(false);

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(CrewLevel.schema),
  });

  const handleCreate = async (data: any) => {
    try {
      setExecuting(true);
      await CrewLevel.create(data);
      props.history.push('/crew_levels');
    } catch (e) {
      alert(e);
    } finally {
      setExecuting(false);
    }
  };

  return (
    <>
      <PageTitle title={props.title} icon={props.icon} />
      <Content>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleCreate)}>
            {loading ? (
              <ContentLoader />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CrewLevelForm />
                </Grid>
                <Grid item xs={12}>
                  <FormActionButton label="登録する" executing={executing} />
                </Grid>
              </Grid>
            )}
          </form>
        </FormProvider>
      </Content>
    </>
  );
};

export default CreateCrewLevel;
