import React, { FC } from 'react';
import { DataGrid, GridColDef, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useDialog } from 'muibox';

import CrewLevel from '../../../models/crew-level/CrewLevel';

import { EditButton, DeleteButton } from '../../../reusable';
import Crew from '../../../models/crew/Crew';

type Props = {
  crewLevels: CrewLevel[];
};

const CrewLevelTable: FC<Props> = (props) => {
  const dialog = useDialog();

  const handleDelete = async (crewLevel: CrewLevel) => {
    const crews = await Crew.getByLevelId(crewLevel.snapshot!.id);
    if (crews.size > 0) {
      window.alert('このレベルに所属するクルーが存在するため削除できません。');
      return;
    }

    const result = await dialog.confirm({ message: `「${crewLevel.name}」を削除します。よろしいですか？` });
    if (!result) return;

    try {
      await CrewLevel.delete(crewLevel);
    } catch (e) {
      alert(e);
    }
  };

  const cols: GridColDef[] = [
    {
      headerName: '操作',
      field: 'crewLevel.actions',
      renderCell: (params) => (
        <>
          <EditButton to={`/crew_levels/${params.row.snapshot!.id}/edit`} />
          <DeleteButton onClick={() => handleDelete(params.row.crewLevel)} />
        </>
      ),
    },
    {
      headerName: 'レベル名',
      field: 'name',
      width: 200,
    },
    {
      headerName: 'レベル',
      field: 'level',
    },
    {
      headerName: '表示順',
      field: 'order',
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        columns={cols}
        rows={props.crewLevels.map((crewLevel) => ({
          ...crewLevel,
          id: crewLevel.snapshot!.id,
        }))}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        autoHeight
      />
    </div>
  );
};

export default CrewLevelTable;
