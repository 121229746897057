import objectPath from 'object-path';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControl, InputAdornment, OutlinedInputProps, TextField } from '@mui/material';

type Props = {
  name: string;
  label: string;
  helperText?: React.ReactNode;
  defaultValue?: any;
  rows?: number;
  rowsMax?: number;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  prepend?: string;
  append?: string;
  size?: 'small' | 'medium';
  type?: string;
  onChange?: OutlinedInputProps['onChange'];
  onBlur?: OutlinedInputProps['onBlur'];
};

const TextFormControl: FC<Props> = (props) => {
  const { register, errors } = useFormContext();

  const error = objectPath.get(errors, props.name);

  return (
    <FormControl fullWidth>
      <TextField
        name={props.name}
        id={props.name}
        label={props.label}
        error={error != null}
        helperText={
          <>
            {error?.message && <div>{error.message}</div>}
            {props.helperText}
          </>
        }
        multiline={props.multiline ?? false}
        rows={props.rows}
        maxRows={props.rowsMax}
        required={props.required ?? false}
        disabled={props.disabled ?? false}
        placeholder={props.placeholder}
        InputProps={{
          readOnly: props.readOnly ?? false,
          startAdornment:
            props.prepend != null ? <InputAdornment position="start">{props.prepend}</InputAdornment> : null,
          endAdornment: props.append != null ? <InputAdornment position="end">{props.append}</InputAdornment> : null,
        }}
        inputRef={register}
        defaultValue={props.defaultValue}
        size={props.size ?? 'medium'}
        type={props.type}
        variant="outlined"
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </FormControl>
  );
};

export default TextFormControl;
