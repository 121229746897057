import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import objectPath from 'object-path';
import React, { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useShopsOnce from '../../views/hooks/shops/useShopsOnce';
import ContentLoader from '../ContentLoader';

type Props = {
  name: string;
  label: string;
  defaultValue?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: Function;
  noneLabel?: string;
  onlyKitchenShops: boolean;
};

const ShopSelectFormControl: FC<Props> = (props) => {
  const { errors, control } = useFormContext();
  const error = objectPath.get(errors, props.name);
  const { shops, loadingShops } = useShopsOnce();

  const kitchenShops = useMemo(() => shops.filter((shop) => !shop.kitchenShopId), [shops]);

  return loadingShops ? (
    <ContentLoader />
  ) : (
    <FormControl
      fullWidth
      error={error != null}
      required={props.required ?? false}
      disabled={props.disabled ?? false}
      variant="outlined"
    >
      <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
      <Controller
        control={control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ onChange, onBlur, value, name, ref }) => (
          <Select
            id={props.name}
            label={props.label}
            labelId={`${props.name}-label`}
            readOnly={props.readOnly ?? false}
            onBlur={onBlur}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
            value={value}
            name={name}
            ref={ref}
          >
            <MenuItem key="none" value="">
              <em>{props.noneLabel ?? '-'}</em>
            </MenuItem>
            {props.onlyKitchenShops
              ? kitchenShops.map((kitchenShop) => (
                  <MenuItem value={kitchenShop.snapshot!.id} className="kitchenShop">
                    {kitchenShop.shortName}
                  </MenuItem>
                ))
              : kitchenShops.map((kitchenShop) => [
                  <hr />,
                  <em>{kitchenShop.shortName}</em>,
                  <MenuItem value={kitchenShop.snapshot!.id} className="kitchenShop">
                    {kitchenShop.shortName}
                  </MenuItem>,
                  ...shops
                    .filter((shop) => shop.kitchenShopId === kitchenShop.snapshot!.id)
                    .map((shop) => <MenuItem value={shop.snapshot!.id}>{shop.shortName}</MenuItem>),
                ])}
          </Select>
        )}
      />
      {error != null ? <FormHelperText>{error?.message}</FormHelperText> : null}
    </FormControl>
  );
};

export default ShopSelectFormControl;
