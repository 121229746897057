import React, { FC } from 'react';

import SpecLabel from './SpecLabel';

type Props = { value: number | undefined };

const ProteinLabel: FC<Props> = (props) => {
  return <SpecLabel value={props.value} prefix="P:" suffix="g" />;
};

export default ProteinLabel;
