import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { Content, PageTitle } from '../../layouts';
import AnycarryAddress from '../../models/anycarry-address/AnycarryAddress';
import { ContentLoader, CreateButton } from '../../reusable';
import AnycarryAddressTable from './AnycarryAddressTable';

const AnycarryAddresses = (props: any) => {
  const [anycarryAddresses, loading, error] = AnycarryAddress.useAll();

  useEffect(() => {
    if (error) alert(error);
  }, [error]);

  return (
    <>
      <PageTitle title={props.title} icon={props.icon}>
        <CreateButton to="/anycarry_addresses/create" />
      </PageTitle>

      <Content>
        <Card>
          {loading ? <ContentLoader /> : <AnycarryAddressTable anycarryAddresses={anycarryAddresses ?? []} />}
        </Card>
      </Content>
    </>
  );
};

export default AnycarryAddresses;
