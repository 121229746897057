import React, { FC } from 'react';
import SpecLabel from './SpecLabel';

type Props = { value: number | undefined };

const CalorieLabel: FC<Props> = (props) => {
  return <SpecLabel value={props.value} suffix="kcal" />;
};

export default CalorieLabel;
