import { useCollectionData } from 'react-firebase-hooks/firestore';
import firebase from '../../utils/Firebase';
import SalesChannel from './SalesChannel';

export default class Shop {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot | null,
    readonly order: number,
    readonly name: string,
    readonly shortName: string,
    readonly imageUrls: string[],
    readonly enabled: boolean,
    readonly batchDelivery: boolean,
    readonly bagSelectable: boolean,
    readonly kitchenShopId: string | null,
    readonly address: string,
    readonly access: string,
    readonly remark: string,
    readonly flashMessage: string,
    readonly openingDays: string,
    readonly openingHours: string,
    readonly email: string,
    readonly manualUrl: string | null,
    readonly receiptPrefix: string,
    readonly initialOrderSequence: number,
    readonly keywords: string | null,
    readonly homepageEnabled: boolean,
    readonly salesChannel: SalesChannel,
  ) {}

  static fromData(snapshot: firebase.firestore.QueryDocumentSnapshot | null, data: any) {
    return new Shop(
      snapshot,
      data.order,
      data.name,
      data.short_name,
      data.image_urls,
      data.enabled,
      data.batch_delivery,
      data.bag_selectable,
      data.kitchen_shop_id,
      data.address,
      data.access,
      data.remark,
      data.flash_message,
      data.opening_days,
      data.opening_hours,
      data.email,
      data.manual_url,
      data.receipt_prefix,
      data.initial_order_sequence,
      data.keywords,
      data.homepage_enabled,
      new SalesChannel(data.sales_channel),
    );
  }

  toData = () => {
    return {
      order: this.order,
      name: this.name,
      short_name: this.shortName,
      image_urls: this.imageUrls,
      enabled: this.enabled,
      batch_delivery: this.batchDelivery,
      kitchen_shop_id: this.kitchenShopId,
      address: this.address,
      access: this.access,
      remark: this.remark,
      flash_message: this.flashMessage,
      opening_days: this.openingDays,
      opening_hours: this.openingHours,
      email: this.email,
      manual_url: this.manualUrl,
      receipt_prefix: this.receiptPrefix,
      initial_order_sequence: this.initialOrderSequence,
      keywords: this.keywords,
      homepage_enabled: this.homepageEnabled,
      sales_channel: this.salesChannel,
    };
  };

  static collectionName = 'shops';

  static docPath = (uid: string) => {
    return `${Shop.collectionName}/${uid}`;
  };

  static converter = {
    toFirestore(item: Shop): firebase.firestore.DocumentData {
      return item.toData();
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Shop {
      const data = snapshot.data(options)!;
      return Shop.fromData(snapshot, data);
    },
  };

  static getAll = async () => {
    const query = firebase
      .firestore()
      .collection(Shop.collectionName)
      .withConverter(Shop.converter)
      .orderBy('order', 'asc');
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => doc.data());
  };

  static getOne = async (uid: string) => {
    const collection = firebase.firestore().collection(Shop.collectionName);
    const snapshot = await collection.doc(uid).withConverter(Shop.converter).get();
    return snapshot.data();
  };

  static getAllEnabledKitchen = async () => {
    const query = firebase
      .firestore()
      .collection(Shop.collectionName)
      .where('kitchen_shop_id', '==', null)
      .where('enabled', '==', true)
      .withConverter(Shop.converter)
      .orderBy('order', 'asc');
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => doc.data());
  };

  static findById = (shops: Shop[], id: string) => shops.find((shop) => shop.snapshot!.id === id);

  static getById = async (id: string) => {
    const snapshop = await firebase
      .firestore()
      .collection(Shop.collectionName)
      .withConverter(Shop.converter)
      .doc(id)
      .get();
    return snapshop.data();
  };
}
