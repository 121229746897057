/* eslint-disable react/jsx-props-no-spreading */
import { Button, Card, CardContent, FormControl, FormHelperText, FormLabel, Grid, Input } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import objectPath from 'object-path';
import React, { useCallback, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import firebase from '../../utils/Firebase';

interface Props {
  name: string;
  label: string;
  bucket: string;
  defaultValue?: any;
  required?: boolean;
  deletable?: boolean;
}

const FileFormControl = (props: Props) => {
  const { register, setValue, errors } = useFormContext();
  const error = objectPath.get(errors, props.name);

  const [uploading, setUploading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [deleted, setDeleted] = useState(false);

  const uploadImageAsPromise = useCallback(
    (file: File) => {
      const fileName = `${uuidv4()}.${file.name.split('.').pop()}`;
      const storage = firebase.storage();
      const storageRef = storage.ref(props.bucket).child(fileName);

      return new Promise<string>((resolve, reject) => {
        const task = storageRef.put(file);

        task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`${percent}% done`);
          },
          (err) => {
            reject(err);
          },
          function complete() {
            task.then((snapshot: firebase.storage.UploadTaskSnapshot) => {
              resolve(snapshot.ref.getDownloadURL());
            });
          },
        );
      });
    },
    [props.bucket],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        alert(
          fileRejections
            .map((rejection) => `${rejection.file.name}: ${rejection.errors.map((err) => err.message).join(', ')}`)
            .join('\n'),
        );
      } else {
        try {
          setUploading(true);

          const downloadUrls = await Promise.all(
            acceptedFiles.map((file) => {
              return uploadImageAsPromise(file);
            }),
          );

          setDownloadUrl(downloadUrls[0]);
          setDeleted(false);
          setValue(props.name, downloadUrls[0], { shouldDirty: true, shouldValidate: true });
        } catch (e) {
          alert(e);
        } finally {
          setUploading(false);
        }
      }
    },
    [props.name, setValue, uploadImageAsPromise],
  );

  const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    minSize: 0,
    maxSize: 500000,
    maxFiles: 1,
  });

  return (
    <>
      <Card className="my-2 shadow-l" style={error != null ? { border: '1px solid red' } : {}}>
        <CardContent>
          <FormControl component="fieldset" fullWidth required={props.required ?? false} error={error != null}>
            <FormLabel component="legend">{props.label}</FormLabel>
            {error != null && <FormHelperText error>{error?.message}</FormHelperText>}
            <div className="dropzone">
              <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper">
                  {isDragAccept && (
                    <div>
                      <div className="d-60 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                        <CheckIcon className="d-30" />
                      </div>
                      <div className="font-size-sm text-success">アップロードに成功しました！</div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-60 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                        <CloseTwoToneIcon className="d-30" />
                      </div>
                      <div className="font-size-sm text-danger">アップロードできない形式のファイルです！</div>
                    </div>
                  )}
                  {!isDragActive && (
                    <div>
                      <div className="d-60 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                        <CloudUploadTwoToneIcon className="d-30" />
                      </div>
                      <div className="font-size-sm">ここにファイルをドラッグアンドドロップしてください</div>
                    </div>
                  )}
                  <small className="py-1 text-black-50">or</small>
                  <div>
                    <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                      <span className="px-2">ファイルを選択</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
                アップロードしたファイル
              </div>
              {downloadUrl == null && props.defaultValue == null && (
                <div className="text-black-50 text-center font-size-sm mb-2">
                  アップロードされたファイルがここに表示されます
                </div>
              )}
              <Grid container spacing={2} justifyContent="center" className="px-2">
                {!deleted && downloadUrl != null && (
                  <Grid item sm={6}>
                    <div className="font-size-sm text-black-50 text-center">
                      <p>新しいファイル</p>
                      <img src={downloadUrl} alt="新しいファイル" style={{ maxWidth: '100%' }} />
                    </div>
                  </Grid>
                )}
                {!deleted && props.defaultValue != null && (
                  <Grid item sm={6}>
                    <div className="font-size-sm text-black-50 text-center">
                      <p>アップロード済</p>
                      <img src={props.defaultValue} alt="アップロード済みのファイル" style={{ maxWidth: '100%' }} />
                    </div>
                  </Grid>
                )}
                {props.deletable && (
                  <Grid item sm={12} style={{ textAlign: 'center' }}>
                    <Button
                      type="button"
                      variant="contained"
                      size="small"
                      className="btn-primary"
                      onClick={() => setDeleted(true)}
                    >
                      画像を削除する
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Input
                type="hidden"
                name={props.name}
                inputRef={register}
                defaultValue={props.defaultValue}
                value={deleted ? '' : downloadUrl}
              />
            </div>
          </FormControl>
        </CardContent>
      </Card>
    </>
  );
};

export default FileFormControl;
