import React from 'react';

const Content = (props: any) => {
  const { sectionHeading, children } = props;

  return (
    <div className="example-card-seamless mb-spacing-6">
      {sectionHeading && <h5 className="display-4 mb-4 font-weight-bold">{sectionHeading}</h5>}
      {children}
    </div>
  );
};

export default Content;
