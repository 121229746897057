import React, { useContext, useEffect, useState } from 'react';

import { faAngleDown, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, List, ListItem, Menu, Tooltip, Typography } from '@mui/material';

import withTMCAuth from '../utils/Auth/withTMCAuth';
import RoleContext from '../utils/Auth/RoleContext';

const HeaderUserbox = (props: { user; signOut }) => {
  const { user, signOut } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const role = useContext(RoleContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple
      >
        <div className="d-block p-0 avatar-icon-wrapper">
          <div className="avatar-icon rounded">
            <img src={user?.photoURL ?? ''} alt="..." />
          </div>
        </div>

        <div className="d-none d-xl-block pl-2">
          <div className="font-weight-bold pt-2 line-height-1">{user?.displayName}</div>
          <span className="text-black-50">{role.roles.join(',')}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={faAngleDown} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}
      >
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
            <Typography className="text-capitalize pl-1 font-weight-bold text-primary">
              <span>プロフィール設定</span>
            </Typography>
            <div className="font-size-xs pr-1">
              <Tooltip title="Change settings" arrow>
                <a href="#/" onClick={(e) => e.preventDefault()}>
                  <FontAwesomeIcon icon={faCog} />
                </a>
              </Tooltip>
            </div>
          </div>
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3"
          >
            <ListItem button className="d-block text-left">
              <a href="#/" onClick={signOut}>
                サインアウト
              </a>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

export default withTMCAuth(HeaderUserbox);
