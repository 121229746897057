import objectPath from 'object-path';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControl, InputAdornment, OutlinedInputProps, TextField } from '@mui/material';

type Props = {
  name: string;
  label: string;
  helperText?: string;
  defaultValue?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium';
  onChange?: OutlinedInputProps['onChange'];
  onBlur?: OutlinedInputProps['onBlur'];
};

const TimeFormControl: FC<Props> = (props) => {
  const { register, errors } = useFormContext();

  const error = objectPath.get(errors, props.name);

  return (
    <FormControl fullWidth>
      <TextField
        name={props.name}
        id={props.name}
        label={props.label}
        error={error != null}
        helperText={error?.message ?? props.helperText}
        required={props.required ?? false}
        disabled={props.disabled ?? false}
        placeholder={props.placeholder}
        InputProps={{
          readOnly: props.readOnly ?? false,
        }}
        inputRef={register}
        defaultValue={props.defaultValue}
        size={props.size ?? 'medium'}
        type="time"
        variant="outlined"
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </FormControl>
  );
};

export default TimeFormControl;
