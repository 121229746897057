import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { Content, PageTitle } from '../../layouts';
import CrewLevel from '../../models/crew-level/CrewLevel';
import { ContentLoader, CreateButton } from '../../reusable';
import CrewLevelTable from './components/CrewLevelTable';

const CrewLevels = (props: any) => {
  const [levels, loading, error] = CrewLevel.useAll();

  useEffect(() => {
    if (error) alert(error);
  }, [error]);

  return (
    <>
      <PageTitle title={props.title} icon={props.icon}>
        <CreateButton to="/crew_levels/create" />
      </PageTitle>

      <Content>
        <Card>{loading ? <ContentLoader /> : <CrewLevelTable crewLevels={levels ?? []} />}</Card>
      </Content>
    </>
  );
};

export default CrewLevels;
