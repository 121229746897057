import clsx from 'clsx';
import React from 'react';

import { StoreContainer } from '../utils/StoreContainer';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = (props) => {
  const storeContainer = StoreContainer.useContainer();

  return (
    <>
      <div
        className={clsx('app-wrapper', {
          'header-drawer-open': false,
          'app-sidebar-collapsed': storeContainer.isActiveSidebar,
          'app-sidebar-mobile-open': storeContainer.isActiveSidebarMobile,
          'app-sidebar-fixed': true,
          'app-header-fixed': true,
          'app-footer-fixed': false,
          'search-wrapper-open': false,
        })}
      >
        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{props.children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Layout);
