import clsx from 'clsx';
import React from 'react';

import { StoreContainer } from '../utils/StoreContainer';
import HeaderUserbox from './HeaderUserbox';

const Header = () => {
  const storeContainer = StoreContainer.useContainer();

  const toggleSidebarMobile = () => {
    storeContainer.setIsActiveSidebarMobile(!storeContainer.isActiveSidebarMobile);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': true,
          'app-header--opacity-bg': true,
        })}
      >
        <div className="app-header--pane">
          <button
            type="button"
            className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
              'is-active': storeContainer.isActiveSidebarMobile,
            })}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div className="app-header--pane">
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
