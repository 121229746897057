/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React from 'react';

import { StoreContainer } from '../utils/StoreContainer';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

const Sidebar = () => {
  const storeContainer = StoreContainer.useContainer();

  const toggleSidebarMobile = () => {
    storeContainer.setIsActiveSidebarMobile(!storeContainer.isActiveSidebarMobile);
  };

  return (
    <>
      <div
        className={clsx('app-sidebar', 'app-sidebar--dark', {
          'app-sidebar--shadow': false,
        })}
      >
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': storeContainer.isActiveSidebarMobile,
        })}
      />
    </>
  );
};

export default React.memo(Sidebar);
