import FormOption from '../FormOption';

export type Channels = 'takeout' | 'office_delivery' | 'catering' | 'stand' | 'eatin' | 'delivery_provider';

export default class SalesChannel {
  static labelMap = {
    takeout: 'テイクアウト',
    eatin: 'イートイン',
    office_delivery: '法人デリバリー',
    catering: '社食',
    stand: 'スタンド',
  };

  constructor(readonly channel: Channels) {}

  label = () => {
    return SalesChannel.labelMap[this.channel] || `unknown ${this.channel}`;
  };

  static options = () => {
    return Object.keys(SalesChannel.labelMap).map((key) => {
      return new FormOption(key, SalesChannel.labelMap[key]);
    });
  };
}
