import React, { FC } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon: any;
  children?: React.ReactNode;
};

const PageTitle: FC<Props> = (props) => {
  return (
    <>
      <div className="app-page-title app-page-title--shadow">
        <div>
          <div className="app-page-title--first">
            <div className="app-page-title--iconbox d-70">
              <div className="d-70 d-flex align-items-center justify-content-center display-1">
                <FontAwesomeIcon icon={props.icon} className="font-size-xxl" />
              </div>
            </div>
            <div className="app-page-title--heading">
              <h1>{props.title}</h1>
              <div className="app-page-title--description">{props.description}</div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">{props.children}</div>
      </div>
    </>
  );
};

export default React.memo(PageTitle);
