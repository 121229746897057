import React, { useState } from 'react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import withTMCAuth from '../utils/Auth/withTMCAuth';

const SignIn = (props: { signInWithGoogle }) => {
  const { signInWithGoogle } = props;
  const history = useHistory();
  const [executingSignInWithGoogle, setExecutingSignInWithGoogle] = useState(false);

  const handleSignInWithGoogle = async () => {
    setExecutingSignInWithGoogle(true);

    try {
      const result = await signInWithGoogle();
      if (result) history.push('/');
    } catch (e) {
      console.log(e);
    } finally {
      setExecutingSignInWithGoogle(false);
    }
  };

  return (
    <>
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Grid item md={10} lg={8} xl={4} className="mx-auto">
                    <div className="text-center">
                      <h1 className="display-4 mb-1 font-weight-bold">ログイン</h1>
                    </div>
                    <div className="text-center py-4 rounded bg-secondary my-4">
                      <div className="text-center py-4">
                        <span className="btn-loading-wrapper">
                          <Button
                            onClick={handleSignInWithGoogle}
                            disabled={executingSignInWithGoogle}
                            className="m-2 btn-pill px-4 font-weight-bold btn-google btn-icon"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={faGoogle} />
                            </span>
                            <span className="btn-wrapper--label">Login with Google</span>
                          </Button>
                          {executingSignInWithGoogle && (
                            <CircularProgress size={22} style={{ margin: '-11px 0 0 -11px' }} />
                          )}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTMCAuth(SignIn);
