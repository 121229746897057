import { useCollectionData } from 'react-firebase-hooks/firestore';

import firebase from '../../utils/Firebase';
import Picture from './Picture';

export default class Crew {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot | null,
    readonly displayName: string,
    readonly furigana: string | null,
    readonly email: string | null,
    readonly level: number | null,
    readonly status: 'active' | 'inactive',
    readonly picture: Picture | null,
  ) {}

  static fromData(snapshot: firebase.firestore.QueryDocumentSnapshot | null, data: any) {
    return new Crew(
      snapshot,
      data.display_name,
      data.furigana,
      data.email,
      data.level,
      data.status,
      data.picture ? Picture.fromData(data.picture) : null,
    );
  }

  static collectionName = 'crews';

  static docPath = (uid: string) => {
    return `${Crew.collectionName}/${uid}`;
  };

  static converter = {
    toFirestore(item: Crew): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Crew {
      const data = snapshot.data(options)!;
      return Crew.fromData(snapshot, data);
    },
  };

  static useAll = () => {
    return useCollectionData<Crew>(firebase.firestore().collection(Crew.collectionName).withConverter(Crew.converter));
  };

  static findById = (crews: Crew[], id: string) => crews.find((shop) => shop.snapshot!.id === id);

  static getById = async (id: string) => {
    const snapshop = await firebase
      .firestore()
      .collection(Crew.collectionName)
      .withConverter(Crew.converter)
      .doc(id)
      .get();
    return snapshop.data();
  };

  static getByLevelId = async (levelId: string) => {
    const snapshop = await firebase
      .firestore()
      .collection(Crew.collectionName)
      .withConverter(Crew.converter)
      .where('level_id', '==', levelId)
      .get();
    return snapshop;
  };
}
