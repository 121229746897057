import { Grid } from '@mui/material';
import React, { FC } from 'react';
import CalorieLabel from './CalorieLabel';
import CarbLabel from './CarbLabel';
import CarbohydrateLabel from './CarbohydrateLabel';
import FatLabel from './FatLabel';
import ProteinLabel from './ProteinLabel';

type Props = {
  calorie: number | undefined;
  carb: number | undefined;
  protein: number | undefined;
  fat: number | undefined;
  carbohydrate: number | undefined;
};

const SpecLabels: FC<Props> = (props) => {
  return (
    <Grid container wrap="nowrap" direction="column">
      <Grid item container wrap="nowrap" spacing={1}>
        <Grid item>
          <CalorieLabel value={props.calorie} />
        </Grid>
        <Grid item>
          <CarbLabel value={props.carb} />
        </Grid>
      </Grid>
      <Grid item container wrap="nowrap" spacing={1}>
        <Grid item>
          <ProteinLabel value={props.protein} />
        </Grid>
        <Grid item>
          <FatLabel value={props.fat} />
        </Grid>
        <Grid item>
          <CarbohydrateLabel value={props.carbohydrate} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SpecLabels;
