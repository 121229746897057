import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import TableActionButton from './TableActionButton';

type Props = {
  to?: string;
  onClick?: () => void;
  tooltip?: string;
};

const DeleteButton: FC<Props> = (props) => {
  return (
    <TableActionButton
      to={props.to}
      onClick={props.onClick}
      tooltip={props.tooltip ?? '削除'}
      icon={<FontAwesomeIcon icon={faTrash} className="font-size-sm" />}
      color="dark"
      neutral={false}
    />
  );
};

export default DeleteButton;
