import objectPath from 'object-path';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import FormOption from '../../models/FormOption';

type Props = {
  name: string;
  label: string;
  options: FormOption[];
  defaultValue?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  multiple?: boolean;
  onChange?: Function;
};

const SelectFormControl: FC<Props> = (props) => {
  const { errors, control } = useFormContext();
  const error = objectPath.get(errors, props.name);

  return (
    <FormControl
      fullWidth
      error={error != null}
      required={props.required ?? false}
      disabled={props.disabled ?? false}
      variant="outlined"
      sx={{
        maxWidth: '100%',
      }}
    >
      <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
      <Controller
        control={control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ onChange, onBlur, value, name, ref }) => (
          <Select
            id={props.name}
            label={props.label}
            labelId={`${props.name}-label`}
            readOnly={props.readOnly ?? false}
            multiple={props.multiple ?? false}
            onBlur={onBlur}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
            value={value}
            name={name}
            ref={ref}
          >
            {props.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {error != null ? <FormHelperText>{error?.message}</FormHelperText> : null}
    </FormControl>
  );
};

export default SelectFormControl;
