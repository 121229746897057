import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  FormControlLabel,
  FormHelperText,
  Switch,
  Input,
} from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from '../../../../dayjs';
import CrewLevel from '../../../../models/crew-level/CrewLevel';
import FormOption from '../../../../models/FormOption';
import Shop from '../../../../models/shop/Shop';
import {
  CheckboxFormControl,
  ContentLoader,
  SelectFormControl,
  TextFormControl,
  DatePickerFormControl,
} from '../../../../reusable';

type Props = {
  crewLevel?: CrewLevel;
};

const CrewLevelForm: FC<Props> = (props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Card>
            <CardHeader title="レベル" />
            <CardContent>
              <Grid item container spacing={2}>
                <Grid item sm={6}>
                  <TextFormControl label="名称" name="name" defaultValue={props.crewLevel?.name} required />
                </Grid>
                <Grid item sm={6}>
                  <TextFormControl label="レベル" name="level" defaultValue={props.crewLevel?.level} required />
                </Grid>
                <Grid item sm={6}>
                  <TextFormControl label="表示順" name="order" defaultValue={props.crewLevel?.order} required />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CrewLevelForm;
