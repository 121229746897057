import clsx from 'clsx';
import React from 'react';

const Footer = () => {
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': false,
          'app-footer--opacity-bg': true,
        })}
      >
        <div className="app-footer--first">
          <span>TOKYO MIX CURRY</span>
          ©&nbsp;2020&nbsp;-&nbsp;crafted&nbsp;with&nbsp;
          <span className="text-danger px-1">❤</span>
          &nbsp;by&nbsp;
          <a href="https://tokyomixcurry.com" target="_blank" title="tokyomixcurry.com" rel="noopener noreferrer">
            tokyomixcurry.com
          </a>
        </div>
        <div className="app-footer--second" />
      </div>
    </>
  );
};

export default React.memo(Footer);
