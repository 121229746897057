import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import { PacmanLoader } from 'react-spinners';

type Props = { hidden?: boolean };

const OverlayLoader: FC<Props> = (props) => {
  return (
    <AnimatePresence>
      {!(props.hidden ?? false) && (
        <motion.div
          key="OverlayLoader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center">
            <div className="d-flex align-items-center flex-column mb-5">
              <PacmanLoader color="#b3cf38" loading />
            </div>
            <div className="text-muted font-size-l text-center">
              「食をアップデート」するのだ！
              <span className="font-size-lg d-block text-dark">読込中...</span>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OverlayLoader;
