import objectPath from 'object-path';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

import FormOption from '../../models/FormOption';

type Props = {
  name: string;
  label: string;
  options: FormOption[];
  defaultValues?: any[];
  required?: boolean;
  disabled?: boolean;
};

const CheckboxFormControl: FC<Props> = (props) => {
  const { register, errors } = useFormContext();
  const error = objectPath.get(errors, props.name);

  return (
    <FormControl
      component="fieldset"
      fullWidth
      required={props.required ?? false}
      disabled={props.disabled ?? false}
      error={error != null}
    >
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup row>
        {props.options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                name={props.name}
                value={option.value}
                inputRef={register}
                defaultChecked={props.defaultValues?.includes(option.value)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxFormControl;
