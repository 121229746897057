/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { FormControl, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import jaLocale from 'date-fns/locale/ja';
import objectPath from 'object-path';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  defaultValue?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium';
};

const MonthPickerFormControl: FC<Props> = (props) => {
  const { control, errors, setValue } = useFormContext();

  const error = objectPath.get(errors, props.name);

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
        <Controller
          control={control}
          id={props.name}
          name={props.name}
          defaultValue={props.defaultValue}
          as={
            <DatePicker
              label={props.label}
              inputFormat="yyyy年MM月"
              toolbarFormat="yyyy年MM月"
              mask="____年__月"
              openTo="year"
              views={['year', 'month']}
              value={props.defaultValue}
              readOnly={props.readOnly}
              onChange={(date: any) => setValue(props.name, date)}
              renderInput={(params) => (
                <TextField
                  required={props.required}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  error={error != null}
                  helperText={error?.message}
                  size={props.size}
                  {...params}
                />
              )}
            />
          }
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default MonthPickerFormControl;
