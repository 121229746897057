import React, { FC } from 'react';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableActionButton from './TableActionButton';

type Props = {
  to?: string;
  onClick?: () => void;
  tooltip?: string;
};

const EditButton: FC<Props> = (props) => {
  return (
    <TableActionButton
      to={props.to}
      onClick={props.onClick}
      tooltip={props.tooltip ?? '編集'}
      icon={<FontAwesomeIcon icon={faEdit} className="font-size-sm" />}
      color="first"
      neutral={false}
    />
  );
};

export default EditButton;
