import React, { FC } from 'react';

import SpecLabel from './SpecLabel';

type Props = { value: number | undefined };

const FatLabel: FC<Props> = (props) => {
  return <SpecLabel value={props.value} prefix="F:" suffix="g" />;
};

export default FatLabel;
