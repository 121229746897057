import React, { FC } from 'react';

import { Button, CircularProgress } from '@mui/material';

type Props = {
  label: string;
  type?: 'submit' | 'reset' | 'button';
  color?: 'primary' | 'first' | 'second' | 'info' | 'success' | 'warning' | 'danger' | 'dark';
  executing?: boolean;
  onClick?: () => void;
};

const FormActionButton: FC<Props> = (props) => {
  const type = props.type ?? 'submit';
  const color = props.color ?? 'success';
  const executing = props.executing ?? false;

  return (
    <Button type={type} variant="contained" className={`btn-${color} m-1`} disabled={executing} onClick={props.onClick}>
      {executing ? <CircularProgress color="inherit" size={16} /> : null}
      <span className="btn-wrapper--label">{props.label}</span>
    </Button>
  );
};

export default FormActionButton;
