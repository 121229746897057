import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@mui/material';

type Props = {
  to: string;
  tooltip?: string;
};

const CreateButton: FC<Props> = (props) => {
  return (
    <NavLink to={props.to}>
      <Tooltip title={props.tooltip ?? '新規作成'}>
        <Button variant="contained" size="small" className="d-40 btn-success">
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} className="opacity-8" />
          </span>
        </Button>
      </Tooltip>
    </NavLink>
  );
};

export default CreateButton;
