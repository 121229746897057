import React from 'react';
import { ClipLoader } from 'react-spinners';

import { Grid } from '@mui/material';

const ContentLoader = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" className="p-3">
      <Grid item className="text-center">
        <ClipLoader size={20} />
      </Grid>
    </Grid>
  );
};

export default ContentLoader;
