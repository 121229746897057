import clsx from 'clsx';
import React, { FC } from 'react';

type Props = {
  title: string;
  color: 'primary' | 'second' | 'success' | 'danger' | 'warning' | 'info' | 'dark';
  neutral?: boolean;
};

const LabelBadge: FC<Props> = (props) => {
  const neutral = props.neutral ?? false;
  const badgeClass = `${neutral ? 'badge-neutral' : 'badge'}-${props.color}`;
  const badgeTextClass = `text-${props.color}`;

  return <div className={clsx('badge', badgeClass, neutral && badgeTextClass)}>{props.title}</div>;
};

export default LabelBadge;
