import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { DialogProvider } from 'muibox';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter, Route } from 'react-router-dom';
import firebase from 'firebase';
import AppRouter from './AppRouter';
import './assets/app.scss';
import './assets/base.scss';
import MuiTheme from './theme';
import ScrollToTop from './utils/ScrollToTop';
import setupIcons from './utils/setupIcons';
import { StoreContainer } from './utils/StoreContainer';
import withTMCAuth from './utils/Auth/withTMCAuth';
import Role from './utils/Auth/Role';
import RoleContext from './utils/Auth/RoleContext';
import SignIn from './pages/SignIn';

const App = (props: { user: any; signOut: any }) => {
  useEffect(() => {
    setupIcons();
  }, []);

  const { user, signOut } = props;
  const [role, setRole] = useState<Role>();

  useEffect(() => {
    if (user) {
      if (user && user.providerData[0].providerId === 'google.com' && user.email.match(/@tokyomixcurry.com$/)) {
        setRole(new Role(['super-admin']));
      } else {
        try {
          const unregisterSlotsObserver = firebase
            .firestore()
            .collection('admins')
            .doc(user.uid)
            .onSnapshot(
              (admin) => {
                if (admin.exists) {
                  const { roles } = admin.data()!;
                  if (roles && roles.length > 0) {
                    setRole(new Role(admin.data()!.roles));
                  } else {
                    signOut();
                  }
                }
              },
              (err) => {
                console.log(err);
                signOut();
              },
            );

          return () => {
            unregisterSlotsObserver();
          };
        } catch (e) {
          signOut();
        }
      }
    }
    return () => {};
  }, [user, signOut]);

  return (
    <>
      {user && role ? (
        <StoreContainer.Provider>
          <RoleContext.Provider value={role}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={MuiTheme}>
                <DialogProvider>
                  <BrowserRouter>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <ScrollToTop>
                        <AppRouter />
                      </ScrollToTop>
                    </QueryParamProvider>
                  </BrowserRouter>
                </DialogProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </RoleContext.Provider>
        </StoreContainer.Provider>
      ) : (
        <SignIn />
      )}
    </>
  );
};

export default withTMCAuth(App);
