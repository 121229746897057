import * as yup from 'yup';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import firebase from '../../utils/Firebase';

export default class AnycarryAddress {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot | null,
    readonly name: string,
    readonly zipcode: string,
    readonly state: string,
    readonly city: string,
    readonly address1: string,
    readonly address2: string,
    readonly tel: string,
    readonly email: string,
    readonly woltVenueId: string | null,
    readonly lat: number | null,
    readonly lng: number | null,
    readonly pickupComment: string | null,
    readonly dropoffComment: string | null,
  ) {}

  static fromData(snapshot: firebase.firestore.QueryDocumentSnapshot | null, data: any) {
    console.log(data);
    return new AnycarryAddress(
      snapshot,
      data.name,
      data.zipcode,
      data.state,
      data.city,
      data.address1,
      data.address2,
      data.tel,
      data.email,
      data.wolt_venue_id ?? null,
      data.lat ?? null,
      data.lng ?? null,
      data.pickup_comment ?? null,
      data.dropoff_comment ?? null,
    );
  }

  toData = () => {
    return {
      name: this.name,
      zipcode: this.zipcode,
      state: this.state,
      city: this.city,
      address1: this.address1,
      address2: this.address2,
      tel: this.tel,
      email: this.email,
      wolt_venue_id: this.woltVenueId,
      lat: this.lat,
      lng: this.lng,
      pickup_comment: this.pickupComment,
      dropoff_comment: this.dropoffComment,
    };
  };

  static collectionName = 'anycarry_addresses';

  static docPath = (uid: string) => {
    return `${AnycarryAddress.collectionName}/${uid}`;
  };

  static schema = yup.object().shape({
    name: yup.string().required('入力してください'),
    zipcode: yup.string().required('入力してください'),
    state: yup.string().required('入力してください'),
    city: yup.string().required('入力してください'),
    address1: yup.string().required('入力してください'),
    address2: yup.string().required('入力してください'),
    tel: yup.string().required('入力してください'),
    email: yup.string().required('入力してください'),
    wolt_venue_id: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    lat: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    lng: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    pickup_comment: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    dropoff_comment: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
  });

  static converter = {
    toFirestore(item: AnycarryAddress): firebase.firestore.DocumentData {
      return item.toData();
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): AnycarryAddress {
      const data = snapshot.data(options)!;
      return AnycarryAddress.fromData(snapshot, data);
    },
  };

  static useAll = () => {
    return useCollectionData<AnycarryAddress>(
      firebase.firestore().collection(AnycarryAddress.collectionName).withConverter(AnycarryAddress.converter),
    );
  };

  static create = (data: any) => {
    const collection = firebase.firestore().collection(AnycarryAddress.collectionName);

    return collection.doc().set(data);
  };

  static getOne = async (uid: string) => {
    const collection = firebase.firestore().collection(AnycarryAddress.collectionName);

    return (await collection.doc(uid).withConverter(AnycarryAddress.converter).get()).data();
  };

  static update = (item: AnycarryAddress, data) => {
    return firebase.firestore().doc(item.snapshot!.ref.path).update(data);
  };

  static delete = (item: AnycarryAddress) => {
    return firebase.firestore().doc(item.snapshot!.ref.path).delete();
  };
}
