import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router-dom';

import firebase from '../utils/Firebase';
import OverlayLoader from './OverlayLoader';

const AuthStateHandler = ({ children }) => {
  const [user, loading] = useAuthState(firebase.auth());

  if (loading) return <OverlayLoader />;

  return user ? children : <Redirect to="/signin" />;
};

export default React.memo(AuthStateHandler);
