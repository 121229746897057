import React, { FC } from 'react';

import SpecLabel from './SpecLabel';

type Props = { value: number | undefined };

const CarbohydrateLabel: FC<Props> = (props) => {
  return <SpecLabel value={props.value} prefix="C:" suffix="g" />;
};

export default CarbohydrateLabel;
