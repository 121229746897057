import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { faArrowsAltH, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@mui/material';

import { StoreContainer } from '../utils/StoreContainer';

const SidebarHeader = () => {
  const storeContainer = StoreContainer.useContainer();

  const toggleSidebarMobile = () => {
    storeContainer.setIsActiveSidebarMobile(!storeContainer.isActiveSidebarMobile);
  };

  const toggleSidebar = () => {
    storeContainer.setIsActiveSidebar(!storeContainer.isActiveSidebar);
  };

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink to="/" title="TOKYO MIX CURRY Admin" className="app-sidebar-logo">
            <div className="app-sidebar-logo--icon" style={{ background: 'transparent' }}>
              <img alt="TOKYO MIX CURRY Admin" src={`${window.location.origin}/logo192.png`} />
            </div>
            <div className="app-sidebar-logo--text">
              <span>管理画面</span>

              <b>TOKYO MIX CURRY</b>
            </div>
          </NavLink>
        </div>
        <Tooltip title="Collapse sidebar" placement="right" arrow>
          <Button onClick={toggleSidebar} className="btn btn-sm collapse-sidebar-btn">
            <FontAwesomeIcon icon={faDotCircle} size="lg" />
          </Button>
        </Tooltip>
        <Button
          className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
            'is-active': storeContainer.isActiveSidebarMobile,
          })}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip title="Expand sidebar" placement="right" arrow>
          <Button onClick={toggleSidebar} className="expand-sidebar-btn btn btn-sm">
            <FontAwesomeIcon icon={faArrowsAltH} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default React.memo(SidebarHeader);
