import * as yup from 'yup';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import firebase from '../../utils/Firebase';

export default class CrewLevel {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot | null,
    readonly level: number,
    readonly order: number,
    readonly name: string,
  ) {}

  static fromData(snapshot: firebase.firestore.QueryDocumentSnapshot | null, data: any) {
    return new CrewLevel(snapshot, data.level, data.order, data.name);
  }

  toData = () => {
    return {
      level: this.level,
      order: this.order,
      name: this.name,
    };
  };

  static collectionName = 'crew_levels';

  static docPath = (uid: string) => {
    return `${CrewLevel.collectionName}/${uid}`;
  };

  static schema = yup.object().shape({
    level: yup.number().required('入力してください'),
    order: yup.number().required('入力してください'),
    name: yup.string().required('入力してください'),
  });

  static converter = {
    toFirestore(item: CrewLevel): firebase.firestore.DocumentData {
      return item.toData();
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): CrewLevel {
      const data = snapshot.data(options)!;
      return CrewLevel.fromData(snapshot, data);
    },
  };

  static useAll = () => {
    return useCollectionData<CrewLevel>(
      firebase
        .firestore()
        .collection(CrewLevel.collectionName)
        .withConverter(CrewLevel.converter)
        .orderBy('order', 'asc'),
    );
  };

  static create = (data: any) => {
    const collection = firebase.firestore().collection(CrewLevel.collectionName);

    return collection.doc().set(data);
  };

  static getOne = async (uid: string) => {
    const collection = firebase.firestore().collection(CrewLevel.collectionName);

    return (await collection.doc(uid).withConverter(CrewLevel.converter).get()).data();
  };

  static update = (item: CrewLevel, data) => {
    return firebase.firestore().doc(item.snapshot!.ref.path).update(data);
  };

  static delete = (item: CrewLevel) => {
    return firebase.firestore().doc(item.snapshot!.ref.path).delete();
  };
}
