/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Content, PageTitle } from '../../layouts';
import AnycarryAddress from '../../models/anycarry-address/AnycarryAddress';
import { ContentLoader, FormActionButton } from '../../reusable';
import AnycarryAddressForm from './components/form/AnycarryAddressForm';

const EditAnycarryAddress = (props: any) => {
  const { anycarryAddressUid } = props.match.params;

  const [loading, setLoading] = useState(false);
  const [anycarryAddress, setAnycarryAddress] = useState<AnycarryAddress | undefined>(undefined);
  const [executing, setExecuting] = useState(false);

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(AnycarryAddress.schema),
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const talk = await AnycarryAddress.getOne(anycarryAddressUid);
        setAnycarryAddress(talk);
      } catch (e) {
        alert(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [anycarryAddressUid]);

  const handleCreate = async (data: any) => {
    try {
      setExecuting(true);
      await AnycarryAddress.update(anycarryAddress!, data);
      props.history.push('/anycarry_addresses');
    } catch (e) {
      alert(e);
    } finally {
      setExecuting(false);
    }
  };

  return (
    <>
      <PageTitle title={props.title} icon={props.icon} description={anycarryAddress?.name ?? ''} />
      <Content>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleCreate)}>
            {loading ? (
              <ContentLoader />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AnycarryAddressForm anycarryAddress={anycarryAddress} />
                </Grid>
                <Grid item xs={12}>
                  <FormActionButton label="更新する" executing={executing} />
                </Grid>
              </Grid>
            )}
          </form>
        </FormProvider>
      </Content>
    </>
  );
};

export default EditAnycarryAddress;
